<template>
  <v-app id="inspire">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app>
        <v-list dense>
          <v-list-item link to="/home" color="indigo darken-4">
            <v-list-item-action>
              <v-icon>mdi-home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            :prepend-icon="model ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            append-icon=""
            color="indigo darken-4"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> Acceso </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item to="/users" link color="indigo darken-4">
              <v-list-item-action>
                <v-icon>mdi-account</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Users </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item link @click="logout">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar app color="indigo darken-4" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Security Transversal</v-toolbar-title>
      </v-app-bar>

      <v-main>
        <v-container>
          <router-view />
        </v-container>
      </v-main>
      <v-footer color="indigo darken-4" app>
        <span class="white--text">Secretaría de TIC - Neiva, Huila &copy; {{ new Date().getFullYear() }}</span>
      </v-footer>
    </v-app>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      drawer: null,
      model: false,
    };
  },

  computed: {
    ...mapState("login", ["token", "user"]),
  },

  methods: {
    logout() {
      localStorage.removeItem("spa_token");
      this.$router.push("/login");
    },
  },
};
</script>
